import React, { FC } from 'react';

import LinksBlock from 'components/LinksBlock/LinksBlock';
import { TBodyProps } from './model';

const elements = {
  'Links Block': ({ title, links }, keyId) => (
    <LinksBlock key={keyId} title={title} links={links} />
  ),
};

const Body: FC<TBodyProps> = ({ content, ...rest }) => (
  <>
    {content?.length
      ? content.map((bodyItem, index) => {
          const keyId = `${bodyItem.structure}_${index}`;

          return elements[bodyItem.structure]
            ? elements[bodyItem.structure]({ ...bodyItem.properties, ...rest }, keyId)
            : null;
        })
      : null}
  </>
);

export default Body;
