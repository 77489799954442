import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import { Link, graphql } from 'gatsby';

import { ILinksBlockProps } from './model';
import './LinksBlock.scss';

const LinksList: FC<Pick<ILinksBlockProps, 'links'>> = ({ links }) => (
  <ul className="links-block__list">
    {links.map(({ properties: { label, link, subNavigation } }) => (
      <li className="links-block__list-item" key={link[0].url}>
        <Link to={link[0].url} className="links-block__item-link">
          {label}
        </Link>
        {subNavigation?.length ? <LinksList links={subNavigation} /> : null}
      </li>
    ))}
  </ul>
);

const LinksBlock: FC<ILinksBlockProps> = ({ title, links }) => (
  <Container fluid className="links-block">
    <h2 className="links-block__title">{title}</h2>
    <LinksList links={links} />
  </Container>
);

export const query = graphql`
  fragment FragmentLinksBlock on TLinksBlockProperties {
    title
    links {
      properties {
        label
        link {
          name
          url
        }
        subNavigation {
          properties {
            label
            link {
              name
              url
            }
          }
        }
      }
    }
  }
`;

export default LinksBlock;
