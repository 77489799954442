import React, { FC } from 'react';
import { graphql } from 'gatsby';

import TopBanner from 'components/TopBanner';
import Layout from 'components/Layout';
import Body from './Body';

import { ISiteMapProps } from './model';
import './SitemapPage.scss';

const SitemapPage: FC<ISiteMapProps> = ({
  data: { page, commonSettings, siteSettings, header, footer },
}) => {
  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    topBanner: [{ properties: topBannerData }],
    body,
  } = page?.nodes?.[0] || {};

  return (
    <Layout
      className="sitemap-page"
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <TopBanner
        {...topBannerData}
        imageStrategy="contain"
        imageStyles={{ position: 'static', width: 'auto' }}
        classes="product-top-banner"
      />
      <Body content={body} />
    </Layout>
  );
};

export const query = graphql`
  query SitemapQuery($link: String = "", $lang: String = "") {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          preTitle
          secText
          closeModalText
          minText
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allUmbracoSitemap(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        openGraphImageUrl
        topBanner {
          properties {
            image {
              ...FragmentGatsbyImage
            }
            imageAlt
            mobileImage {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentSmallImage
              }
              mobileImage {
                childImageSharp {
                  fluid(maxWidth: 320, maxHeight: 220, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            mobileImageAlt
            title {
              value
            }
            isContentWrapped
          }
        }
        body {
          ... on TLinksBlockStructure {
            structure
            properties {
              ...FragmentLinksBlock
            }
          }
        }
      }
    }
  }
`;

export default SitemapPage;
